<template>

  <div :class="'container-fluid dashboard--config-page mobile_' + isMobile()">

    <!-- CONTENT : HEADER -->

    <FarmgodHeader>
      <div class='row'>
        <div class='col-sm-12'>
          <Logo v-bind:link="'overview'" v-bind:title="'Powered by FarmGod.io'"/>
        </div>
      </div>
      <div class='row'>
        <section class='col alert alert-danger pulsate fI-slow' v-if='liveStatErrorMessage'>
          <b><translate>LIVESTAT-ERROR</translate> {{ liveStatErrorMessage.error_code }}</b><br/>{{ liveStatErrorMessage.error_detail }}
        </section>
        <section class='col alert alert-info pulsate fI-slow' v-if='!liveStatErrorMessage && latestStatMessage.miner_count_online === 0'>
          <b><translate>ATTENTION</translate></b><br/><translate>No miners online!</translate>
        </section>
        <NavBar v-bind:farm="farm" />
      </div>
    </FarmgodHeader>

    <!-- CONTENT : CORE -->

    <div class='row' style="margin-bottom:15px;">

      <!-- CONTENT : SIDEBAR LEFT -->

      <div v-if='!isMobile()' class='col-2'>
        <SidebarLeft v-bind:farm="farm" v-bind:latestStatMessage="latestStatMessage" />
      </div>

      <!-- CONTENT : CONFIGURATION -->

      <div :class="!isMobile() ? 'col-8' : 'col-sm-12'">

        <div class="container_wrapper">

          <div class="container_header_detail">
            <span class="fI"><i class="fas fa-cogs fas-left"></i> <translate>GLOBAL MINER SETTINGS</translate></span> <small v-if='minerConfig' class="color01">_ {{ minerConfig.updated_at }}</small>
          </div>

          <div class="container_body_detail" style="margin-bottom: 0px; padding: 0px 0px 0 20px;">

            <FormulateForm
              v-if='minerConfig'
              @submit="submitFormHandler"
              id="cfgForm"
              style='overflow: hidden;'
              :values='minerConfig'
              ref='configForm'
              :errors='minerConfigErrors'
              @failed-validation='scrollToErrors'
            >

              <div id="visibleConfig" class="overflow-auto fI-slow">

                <div style="margin-right: 20px;">

                  <!--

                  <label class="wideLabel"><i class="fas fas-xsBtn fas-left fa-users-cog"></i>BASIC SETUP</label>

                  <FormulateInput
                      :label="$gettext('DATA UPDATE FREQUENCY')"
                      :tooltip="$gettext('The frequency in which miner stats will be updated throughout the interface.')"
                      type='select'
                      :options="{ '120': '120 Seconds' }"
                      name='telemetry_submit_period'
                      disabled
                      style="opacity: 0.7; cursor: not-allowed;"
                  />

                  <FormulateInput
                      :label="$gettext('DISABLE AUTOUPGRADE')"
                      :tooltip="$gettext('When auto-upgrade is turned on, the machine will periodically check for a new version of Braiins OS+ and upgrade to it automatically when found. This feature is turned on by default after switching from stock firmware, but it has to be turned on manually if the user upgraded from an older version of Braiins OS or Braiins OS+.')"
                      name='disable_autoupgrade'
                      class="enabledCheck"
                      type='checkbox'
                      label-position="before"
                  />

                  -->

                  <!-- AUTOTUNE -->

                  <fieldset ref='autotuneConfig' class='scroll-anchor configGrp'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>AUTOTUNING</translate></label>
                    <FormulateInput
                        type='group'
                        name='autotune_config'
                    >
                      <FormulateInput
                        :label="$gettext('ENABLED')"
                        :tooltip="$gettext('If enabled, your asic chips will be autotuned. An autotune template will be stored on your machine once autotuning has finished.')"
                        name='enabled'
                        class="enabledCheck"
                        @input='toggleAutotuneConfig'
                        type='checkbox'
                        label-position="before"
                      />
                      <div
                        class='fI-slow'
                        v-if='minerConfig.autotune_config[0] && minerConfig.autotune_config[0].enabled'>
                        <FormulateInput
                          v-if='hasBeenWarned !== true'
                          :label="$gettext('PSU POWER LIMIT')"
                          :tooltip="$gettext('The psu_power_limit can hold numeric values (min. 100 and max. 5000), representing the PSU power limit (in Watts) for three hashboards and the control board.')"
                          autocomplete="new-password"
                          name='psu_power_limit'
                          @focus='$refs.powerlimitWarning.openModal();'
                          error-behavior="value"
                          id="power_limit_input"
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                        />
                        <FormulateInput
                          v-else
                          :label="$gettext('PSU POWER LIMIT')"
                          :tooltip="$gettext('The psu_power_limit can hold numeric values (min. 100 and max. 5000), representing the PSU power limit (in Watts) for three hashboards and the control board.')"
                          autocomplete="new-password"
                          name='psu_power_limit'
                          validation='optional|min:100,value|max:5000,value'
                          error-behavior="value"
                          id="power_limit_input"
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                        />
                      </div>
                    </FormulateInput>
                  </fieldset>

                  <Modal ref="powerlimitWarning">
                  <template v-slot:header>
                    <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>WARNING</translate> <small class="color01">_ <translate>A NOTE REGARDING POWERLIMIT</translate></small></h5>
                  </template>
                  <template v-slot:body>
                    <form id="enableTelemetryForm">
                      <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
                        <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
                          <div v-translate>
                            We strongly advice <b class="red">&laquo; against &raquo;</b> changing the default 'powerlimit'
                          </div>
                          <div v-translate>
                            in case you have <b>different models</b> (S9+S17 eg.) in your current farm!
                          </div>
                        </div>
                      </div>
                    </form>
                  </template>
                  <template v-slot:footer>
                    <div style="display:inline-flex;width:80%;">
                      <button class='btn btn-success pulsate' @click.prevent="hasBeenWarned = true; $refs.powerlimitWarning.closeModal();"><i class="fas fa-grin-squint-tears fas-left"></i><translate>I KNOW WHAT I AM DOING</translate></button>
                    </div>
                  </template>
                  </Modal>
                  <!-- GLOBAL POWER CONFIG (IF AUTOTUNE DISABLED) -->

                  <fieldset ref='globalPowerConfig' class='scroll-anchor configGrp fI-slow' v-if='!minerConfig.autotune_config[0].enabled'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>GLOBAL POWER CONFIG</translate></label>
                    <FormulateInput
                        type='group'
                        name='global_power_config'>

                      <FormulateInput
                          v-if='asicBoostToggling'
                          :label="$gettext('ENABLE ASIC BOOST')"
                          :tooltip="$gettext('Enable or disable AsicBoost support. (default=enabled)')"
                          name='enable_asic_boost'
                          class="enabledCheck"
                          type='checkbox'
                          label-position="before"
                          />
                      <FormulateInput
                          :label="$gettext('FREQUENCY')"
                          :tooltip="$gettext('Set default chip frequency in MHz for all hash chains. (default=650.0)')"
                          name='frequency'
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                        />
                      <FormulateInput
                          :label="$gettext('VOLTAGE')"
                          :tooltip="$gettext('Set default voltage in V for all hash chains. (default=8.8)')"
                          name='voltage'
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                          step=".1"
                        />
                    </FormulateInput>
                  </fieldset>

                  <!-- POOL CONFIG -->

                  <div ref='poolGroup' class='scroll-anchor'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>POOL GROUPS</translate></label>
                    <FormulateInput
                        type='group'
                        name='pool_groups'
                        #default="{ index }"
                        :add-label="$gettext('NEW POOL GROUP')"
                        :remove-label="$gettext('DELETE')"
                        :key='poolGroupUpdateTimestamps'
                        :debounce='50'
                        >
                        <fieldset class='configGrp' v-if='minerConfig.pool_groups[index]'>
                          <legend class="subSubLabel uc">
                            <a class="delPoolConfig" @click="deletePoolGroup(index)" :title="$gettext('Delete pool group')"><i class="fas fas-xsBtn fas-left fa-minus-circle"></i></a><translate>GROUP</translate> #{{ index+1 }} / <b>{{ minerConfig.pool_groups[index].name }}</b>
                          </legend>
                          <!--div class='field-with-right-button'-->
                            <FormulateInput
                                :label="$gettext('GROUP NAME')"
                                :tooltip="$gettext('The name of your pool group.')"
                                name='name'
                                validation='min:5,length|max:20,length'
                                @change="value => { updatePoolGroupName(index, value) }"
                                type='text'
                                />
                            <!--
                            <a v-if="!isMobile()" class="btn btn-danger fctrl_btn right-button" @click="deletePoolGroup(index)" :title="$gettext('Delete pool group')"><font-awesome-icon class='fas-left' icon='minus-circle'/> <translate>DELETE</translate></a>
                            <a v-else class="btn btn-danger fctrl_btn" @click="deletePoolGroup(index)" :title="$gettext('Delete pool group')"><i class="fas fas-xsBtn fas-trash fa-minus-circle"></i></a>
                          </div-->
                          <FormulateInput
                              type='hidden'
                              name='cache_timestamp' />

                          <FormulateInput
                              :label="$gettext('WORK DISTRIBUTION MODE')"
                              :tooltip="$gettext('Set to either load-balance strategy on a \'Quota\' basis or \'Fixed Share Ratio\'.')"
                              type='select'
                              :options="{ quota: 'Group Quota', fixed_share_ratio: 'Fixed Share Ratio' }"
                              name='work_distribution_mode'
                              @input="value => { updateWorkDistributionSettings(index, value) }"
                          />
                          <FormulateInput
                              v-if='minerConfig.pool_groups[index].work_distribution_mode === "quota"'
                              :label="$gettext('GROUP QUOTA')"
                              :tooltip="$gettext('Work is distributed with the load-balance strategy on a Quota basis.')"
                              autocomplete="new-password"
                              name='quota'
                              validation='number|min:1,value'
                              type='number'
                              step='1'
                          />
                          <FormulateInput
                              v-else
                              :label="$gettext('FIXED SHARE RATIO')"
                              :tooltip="$gettext('Work is distributed with the load-balance strategy with a Fixed Share Ratio.')"
                              autocomplete="new-password"
                              name='fixed_share_ratio'
                              validation='bail|number|between:0.0,1'
                              :validation-messages="{ between: 'Fixed share ratio must be between 0.01 and 0.99.' }"
                              type='number'
                              step='.01'
                              max="1"
                          />

                          <div ref='poolConfig' class='scroll-anchor configGrp'>
                            <label class='uc subLabel'><font-awesome-icon class='fas-left' icon='list-ul'/><b>{{ minerConfig.pool_groups[index].name }}</b> / <translate>POOLS</translate></label>
                            <FormulateInput
                                type='group'
                                name='pool_configs'
                                :repeatable='false'
                                #default="{ model, index }"
                                >

                                <fieldset class='configGrp' v-if='index !== null && model && model[index] && model[index].id'>
                                  <legend class="subSubLabel uc">
                                    <a class="delPoolConfig" @click="deletePoolConfig(model[index])"><i class="fas fas-xsBtn fas-left fa-minus-circle"></i></a><translate>Pool</translate> #{{ index+1 }}
                                  </legend>

                                  <FormulateInput
                                      :label="$gettext('ENABLED')"
                                      :tooltip="$gettext('Initial state of the pool after initialization. (default=enabled)')"
                                      name='enabled'
                                      class="enabledCheck"
                                      type='checkbox'
                                      label-position="before"
                                  />

                                  <FormulateInput
                                      :label="$gettext('POOL URL')"
                                      autocomplete="new-password"
                                      :tooltip="$gettext('Mandatory argument for server URL specified in the format: \'scheme://HOSTNAME:PORT/POOL_PUBLIC_KEY\'')"
                                      name='url'
                                      type='url'
                                      :validation="[['matches', /^(drain|stratum\+tcp|stratum\+ssl|stratum\+insecure|stratum2\+tcp|http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/]]"
                                      :validation-messages="{ matches: 'Pool url must match a valid pool url scheme' }"
                                      placeholder='eg. stratum2+tcp://v2.stratum.slushpool.com/u95GEReVMjK6k5YqiSFNqqTnKU4ypU2Wm8awa6tmbmDmk1bWt'
                                  />

                                  <FormulateInput
                                      :label="$gettext('POOL USER')"
                                      :tooltip="$gettext('Mandatory argument for username specified in format: \'USERNAME\'')"
                                      :validation="[['matches', /^[a-zA-Z0-9_\-]+$/]]"
                                      :validation-messages="{ matches: 'Username must only contain alpha numerics, underscores and dashes.' }"
                                      autocomplete="new-password"
                                      name='username'
                                      type='text'
                                      placeholder='eg. myusername'
                                  />

                                  <FormulateInput
                                      :label="$gettext('POOL PASSWORD')"
                                      :tooltip="$gettext('Optional password settings.')"
                                      autocomplete="new-password"
                                      name='password'
                                      type='text'
                                      :placeholder="$gettext('optional')"
                                    />
                                </fieldset>
                            </FormulateInput>

                            <div style="display: inline-flex; width: 100%;">
                              <a class="new-pool-button btn btn-info" @click="addPoolConfigForPoolGroup(index)"><i class="fas fas-xsBtn fas-left fa-plus-circle"></i><translate>NEW POOL FOR GROUP</translate> '{{ minerConfig.pool_groups[index].name }}'</a>
                            </div>
                          </div>

                        </fieldset>
                    </FormulateInput>

                    <div style="display: inline-flex; width: 100%;">
                      <a id='new-pool-group-button' class="btn btn-info" @click="addPoolGroup" style="margin-top:10px;"><i class="fas fas-xsBtn fas-left fa-plus-circle"></i><translate>POOL GROUP</translate></a>
                    </div>
                  </div>

                  <fieldset ref='temperatureConfig' class='scroll-anchor configGrp'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>TEMPERATURE</translate></label>
                    <FormulateInput
                        type='group'
                        name='temperature_config' >

                        <FormulateInput
                            :label="$gettext('MODE')"
                            @input="switchTemperatureMode"
                            :tooltip="$gettext('The controller can operate in different modes:')"
                            type='select'
                            :options='{ "null": "Use Device-Default", 0: "Disabled", 1: "Automatic", 2: "Manual" }'
                            name='mode'
                        />
                        <FormulateInput
                            v-if="temperatureModeIsSetToAutomatic"
                            :label="$gettext('TARGET TEMPERATURE')"
                            :tooltip="$gettext('Set target temperature in Celsius (default=89.0). This option is ONLY used when \'temp_control.mode\' is set to \'auto\'!')"
                            class='fI-slow'
                            autocomplete="new-password"
                            name='target_temperature'
                            validation='optional|min:0,value|max:200,value'
                            error-behavior="value"
                            type='number'
                            :placeholder="$gettext('Use Device-Default...')"
                            step='1'
                        />
                        <FormulateInput
                            v-if='temperatureModeIsSet'
                            :label="$gettext('HOT TEMPERATURE')"
                            :tooltip="$gettext('Set hot temperature in Celsius (default=100.0). When this temperature is reached, the fan speed is set to 100%.')"
                            autocomplete="new-password"
                            name='hot_temperature'
                            validation='optional|min:0,value|max:200,value'
                            error-behavior="value"
                            class='fI-slow'
                            type='number'
                            :placeholder="$gettext('Use Device-Default...')"
                            step='1'
                        />
                        <FormulateInput
                            v-if='temperatureModeIsSet'
                            class='fI-slow'
                            :label="$gettext('DANGEROUS TEMPERATURE')"
                            :tooltip="$gettext('Set dangerous temperature in Celsius (default=110.0). When this temperature is reached, the mining is turned off! WARNING: setting this value too high may damage the device!')"
                            autocomplete="new-password"
                            name='dangerous_temperature'
                            validation='optional|min:0,value|max:200,value'
                            error-behavior="value"
                            type='number'
                            :placeholder="$gettext('Use Device-Default...')"
                            step='1'
                        />
                    </FormulateInput>
                  </fieldset>

                  <fieldset ref='fanConfig' class='scroll-anchor configGrp'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>FAN CONFIG</translate></label>
                    <FormulateInput
                        type='group'
                        name='fan_config'>
                      <FormulateInput
                          v-if='minerConfig.temperature_config && minerConfig.temperature_config[0].mode === "2" || minerConfig.temperature_config[0].mode === 2'
                          :tooltip="$gettext('Set a fixed fan speed in % (default=70). This option is NOT used when temp_control.mode is set to \'auto\'!')"
                          :label="$gettext('FAN SPEED')"
                          autocomplete="new-password"
                          name='speed'
                          validation='optional|min:0,value|max:100,value'
                          error-behavior="value"
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                          step='1'
                      />
                      <FormulateInput
                          :tooltip="$gettext('Set the minimum number of fans required for BOSminer to run (default=1). To completely disable fan control, set \'speed\' and \'min_fans\' to 0.')"
                          :label="$gettext('MIN FANS')"
                          autocomplete="new-password"
                          name='min_fans'
                          validation='optional|min:0,value|max:4,value'
                          error-behavior="value"
                          type='number'
                          :placeholder="$gettext('Use Device-Default...')"
                          step='1'
                      />
                    </FormulateInput>
                  </fieldset>

                  <fieldset ref='powerScaleConfig' class='scroll-anchor configGrp'>
                    <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>DYNAMIC POWER SCALING</translate></label>
                    <FormulateInput
                        type='group'
                        name='power_scaling'
                    >
                      <FormulateInput
                          :label="$gettext('ENABLED')"
                          :tooltip="$gettext('Here you can enable or disable Dynamic Power Scaling. (default=disabled)')"
                          name='enabled'
                          class="enabledCheck"
                          @input='togglePowerScaling'
                          type='checkbox'
                          label-position="before"
                          />
                      <span
                          v-if='minerConfig.power_scaling[0].enabled'>
                        <FormulateInput
                            :label="$gettext('POWER STEP')"
                            :tooltip="$gettext('The power_step can hold numeric values (min. 100 and max. 1000), representing the power limit step-down (in Watts), which happens each time miner hits HOT temperature.')"
                            autocomplete="new-password"
                            name='power_step'
                            validation='optional|min:100,value|max:1000,value'
                            error-behavior="value"
                            step='1'
                            type='number'
                            class='fI-slow'
                            :placeholder="$gettext('Use Device-Default...')"
                        />
                        <FormulateInput
                            :label="$gettext('MIN PSU POWER LIMIT')"
                            :tooltip="$gettext('The min_psu_power_limit can hold numeric values (min. 100 and max. 5000), representing the minimal PSU power limit for the Dynamyc Power Scaling.')"
                            autocomplete="new-password"
                            name='min_psu_power_limit'
                            validation='optional|min:100,value|max:5000,value'
                            error-behavior="value"
                            type='number'
                            class='fI-slow'
                            :placeholder="$gettext('Use Device-Default...')"
                            step='10'
                        />
                        <FormulateInput
                            :label="$gettext('SHUTDOWN ENABLED')"
                            :tooltip="$gettext('If psu_power_limit is at min_psu_power_limit level and miner is still HOT and shutdown_enabled is true, then miner is shut down for a period of time.')"
                            name='shutdown_enabled'
                            class="enabledCheck"
                            @input='togglePowerScalingShutdown'
                            type='checkbox'
                            label-position="before"
                            :placeholder="$gettext('Use Device-Default...')"
                        />
                        <FormulateInput
                            v-if='minerConfig.power_scaling[0].shutdown_enabled === true'
                            :label="$gettext('SHUTDOWN DURATION HOURS')"
                            :tooltip="$gettext('Define (in hours) for how long the miner will stay shut down. After that, miner is started but with the initial value of psu_power_limit. (PSU power limit in the Autotuning section)')"
                            autocomplete="new-password"
                            name='shutdown_duration_hours'
                            class="fI-slow"
                            type='number'
                            :placeholder="$gettext('Use Device-Default...')"
                            step='any'
                        />
                      </span>
                    </FormulateInput>
                  </fieldset>
                  <!--div class="" style="display: inline-flex; width: 100%; margin-top:10px;margin-bottom:10px;">
                    <button class="btn btn-success" type='submit'><i class="fas fas-xsBtn fa-save fas-left"></i>SAVE CHANGES</button>
                  </div-->

                </div>

              </div>

              <ConfigFooter
                :isMobile='isMobile()'
                :parentRefs='$refs'
                />

            </FormulateForm>

          </div>

        </div>

      </div>

      <!-- CONTENT : SIDEBAR RIGHT -->

      <div v-if='!isMobile()' class='col-2'>
        <SidebarRight v-bind:latestStatMessage="latestStatMessage" v-bind:farm="farm" />
      </div>

    </div>

    <!-- CONTENT : FOOTER -->

    <FarmgodFooter>
      <LoggedInFooter />
    </FarmgodFooter>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->

    <Modal ref="configUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 3em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 3px; font-size: 1em; margin-top: 9px; margin-bottom: 10px;color: #13A454;"> <translate>UPDATE SUCCESSFUL</translate> </div>
            <div style="width: 60%; margin: auto;"><small><span class="grey"><translate>During the configuration update process, data (eg. pool status, stats) might be displayed inaccurately for several minutes.</translate></span></small></div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.configUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->

    <Modal ref="configUpdateError" :errorDetail='this.$data.configUpdateError'>
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
              <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
              <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.configUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { FARM__READ, MINER_CONFIG__UPDATE, FARM__READ_MINER_CONFIG, LIVE_STAT__DISCONNECT, LIVE_STAT__CONNECT } from '@/store/actions.type'

import Farm from '@/models/farm'
import MinerConfig from '@/models/miner-config'
import TemperatureConfig from '@/models/miner-config/temperature'
import AutotuneConfig from '@/models/miner-config/autotune'
import PowerScaling from '@/models/miner-config/power-scaling'
import PoolGroup from '@/models/miner-config/pool-group'
import PoolConfig from '@/models/miner-config/pool-config'

import FarmgodHeader from '@/components/layout/TheHeader'
import NavBar from '@/components/views/Detail/NavBar'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedInFooter from '@/components/layout/Footers/LoggedIn'
import SidebarLeft from '@/components/layout/SidebarLeft'
import SidebarRight from '@/components/layout/SidebarRight'
import Modal from '@/components/widgets/Modal.vue'

import ConfigFooter from '@/components/views/Detail/Config/Footer'

export default {
  name: 'miner-config',
  components: {
    FarmgodHeader,
    Logo,
    NavBar,
    SidebarLeft,
    SidebarRight,
    FarmgodFooter,
    LoggedInFooter,
    ConfigFooter,
    Modal
  },
  methods: {
    addPoolConfigForPoolGroup (index) {
      const pG = this.minerConfig.pool_groups[index]
      PoolConfig.insert({
        data: {
          poolgroup_id: pG.id
        }
      }).then((result) => {
        PoolGroup.update({
          where: pG.id,
          data: { cache_timestamp: Date.now() }
        })
      })
    },
    deletePoolConfig (model) {
      PoolConfig.delete(model.id).then(() => {
        PoolGroup.update({
          where: model.poolgroup_id,
          data: { cache_timestamp: Date.now() }
        })
      })
    },
    addPoolGroup () {
      const minerconfigId = this.minerConfig.id
      PoolGroup.insert({
        data: {
          minerconfig_id: minerconfigId
        }
      })
    },
    deletePoolGroup (index) {
      const pG = this.minerConfig.pool_groups[index]
      PoolGroup.delete(pG.id)
    },
    togglePowerScaling (enabled) {
      PowerScaling.update({
        where: this.minerConfig.power_scaling[0].id,
        data: { enabled }
      })
    },
    togglePowerScalingShutdown (shutdownEnabled) {
      PowerScaling.update({
        where: this.minerConfig.power_scaling[0].id,
        data: { shutdown_enabled: shutdownEnabled }
      })
    },
    toggleAutotuneConfig (enabled) {
      AutotuneConfig.update({
        where: this.minerConfig.autotune_config[0].id,
        data: { enabled }
      })
    },
    updatePoolGroupName (index, e) {
      const item = this.minerConfig.pool_groups[index]
      PoolGroup.update({
        where: item.id,
        data: { name: e.target.value }
      })
    },
    updateWorkDistributionSettings (index, wdm) {
      const item = this.minerConfig.pool_groups[index]
      if (wdm === 'quota') {
        PoolGroup.update({
          where: item.id,
          data: { work_distribution_mode: wdm, fixed_share_ratio: null }
        })
      } else {
        PoolGroup.update({
          where: item.id,
          data: { work_distribution_mode: wdm, quota: null }
        })
      }
    },
    configFormIsValid () {
      const isValid = (document.querySelectorAll('.formulate-input-error').length === 0)
      return isValid
    },
    scrollToErrors () {
      document.querySelector('.formulate-input-error').scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    scrollToAnchorPoint (refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    },
    switchTemperatureMode (mode) {
      TemperatureConfig.update({
        where: this.minerConfig.temperature_config[0].id,
        data: { mode }
      })
    },
    submitFormHandler (data) {
      PoolGroup.deleteAll()
      const errorTypes = ['autotune_config', 'fan_config', 'temperature_config', ['pool_groups', ['pool_configs']]]
      data.autotune_config = data.autotune_config[0]
      data.temperature_config = data.temperature_config[0]
      const tempMode = parseInt(data.temperature_config.mode)
      data.temperature_config.mode = tempMode

      data.fan_config = data.fan_config[0]

      if (tempMode !== 2) {
        delete data.fan_config.speed
      }

      if (data.global_power_config && data.global_power_config[0]) {
        data.global_power_config = data.global_power_config[0]
        errorTypes.push('global_power_config')
      }
      if (data.power_scaling && data.power_scaling[0]) {
        data.power_scaling = data.power_scaling[0]
        errorTypes.push('power_scaling')
      }

      this.minerConfig.clearErrors(errorTypes).then(() => {
        this.$nextTick(async () => {
          if (this.configFormIsValid()) {
            await this.$store.dispatch(MINER_CONFIG__UPDATE, { configId: this.minerConfig.id, configData: data }).then((result) => {
              this.$refs.configUpdateSuccess.openModal()
              this.$store.dispatch(FARM__READ_MINER_CONFIG, this.$route.params.farm_id)
            }).catch((err) => {
              if (!err.response) {
                throw err
              }

              this.errorDetailFull = JSON.parse(JSON.stringify(err.response.data))
              this.errorDetail = this.errorDetailFull.detail[0].msg
              this.$refs.configUpdateError.openModal()
            })
          } else {
            document.querySelector('.formulate-input-error').scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
        })
      })
    }
  },
  data () {
    return {
      hasBeenWarned: false,
      asicBoostToggling: false,
      configUpdateError: {}
    }
  },
  computed: {
    ...mapGetters({ latestStatMessage: 'livestats/latestStatMessage', liveStatErrorMessage: 'livestats/liveStatErrorMessage' }),
    farm () {
      const farm = Farm.find(this.$route.params.farm_id)
      if (farm) {
        return farm
      } else {
        this.$store.dispatch(FARM__READ, this.$route.params.farm_id)
        return null
      }
    },
    poolGroupUpdateTimestamps () {
      return this.minerConfig.pool_groups.map((poolGroup) => { return poolGroup.cache_timestamp }).join()
    },
    minerConfig () {
      const minerConfig = MinerConfig.query()
        .with('autotune_config')
        .with('pool_groups')
        .with('pool_groups.pool_configs')
        .with('global_power_config')
        .with('hash_chain_configs')
        .with('power_scaling')
        .with('temperature_config')
        .with('fan_config')
        .where({ deviceset_id: this.$route.params.farm_id })
        .first()

      if (minerConfig) {
        minerConfig.autotune_config = [minerConfig.autotune_config]
        minerConfig.global_power_config = [minerConfig.global_power_config]
        // minerConfig.hash_chain_configs = [minerConfig.hash_chain_configs]
        minerConfig.power_scaling = [minerConfig.power_scaling]
        minerConfig.temperature_config = [minerConfig.temperature_config]
        minerConfig.fan_config = [minerConfig.fan_config]
      }

      return minerConfig
    },
    minerConfigErrors () {
      const errors = this.minerConfig.errors
      errors.autotune_config = this.minerConfig.autotune_config[0].errors
      errors.global_power_config = this.minerConfig.global_power_config[0].errors

      const poolGroupErrors = []
      this.minerConfig.pool_groups.forEach((poolGroup) => {
        const pgErrors = poolGroup.errors
        pgErrors.pool_configs = []

        poolGroup.pool_configs.forEach((poolConfig) => {
          pgErrors.pool_configs.push(poolConfig.errors)
        })

        poolGroupErrors.push(poolGroup.errors)
      })

      errors.pool_groups = poolGroupErrors
      // errors.hash_chain_configs = this.minerConfig.hash_chain_configs[0].errors
      errors.power_scaling = this.minerConfig.power_scaling[0].errors
      errors.temperature_config = this.minerConfig.temperature_config[0].errors
      errors.fan_config = this.minerConfig.fan_config[0].errors

      return errors
    },
    temperatureModeIsSet () {
      const tempConf = this.minerConfig.temperature_config[0]
      const result = tempConf.mode !== 'null'
      return result
    },
    temperatureModeIsSetToAutomatic () {
      const tempConf = this.minerConfig.temperature_config[0]
      const result = tempConf.mode !== null && tempConf.mode.toString() === '1'
      return result
    }
  },
  beforeDestroy () {
    MinerConfig.deleteAll()
  },
  created () {
    this.$store.dispatch(FARM__READ_MINER_CONFIG, this.$route.params.farm_id)
    this.$store.dispatch(`livestats/${LIVE_STAT__DISCONNECT}`, this.$route.params.farm_id)
    this.$store.dispatch(`livestats/${LIVE_STAT__CONNECT}`, { farmId: this.$route.params.farm_id })
  }
}
</script>

<style>
  @import '../../assets/style/minerconfig.scss';
</style>
