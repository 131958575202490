<template>

  <span>

    <!-- CONTENT : CONFIG SECTION MENU -->

    <div v-if='!isMobile' class="mainFooter" style="margin-left:0;margin-right:0;">
      <!--button @click="scrollToAnchorPoint('basicConfig')" type="button" class="btn btn-primary btn-main configBtn pulsate"><i class="fas fa-cog fas-left"></i>BASIC</button-->
      <!--button v-if="autotuning" @click="scrollToAnchorPoint('autotuneConfig')" type="button" class="fI btn btn-secondary btn-main configBtn"><i class="fas fa-tachometer-alt fas-left"></i><translate>AUTOTUNE</translate></button-->
      <!--button v-if="autotuning && !autotuning.enabled" @click="scrollToAnchorPoint('globalPowerConfig')" type="button" class="fI btn btn-secondary btn-main configBtn"><i class="fas fa-bolt fas-left"></i><translate>POWER</translate></button-->
      <!--button v-if="autotuning && !autotuning.enabled" @click="scrollToAnchorPoint('hashChainConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-bars fas-left"></i>PER CHAIN</button-->
      <!--button @click="scrollToAnchorPoint('poolConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-bezier-curve fas-left"></i><translate>POOLS</translate></button-->
      <!--button @click="scrollToAnchorPoint('temperatureConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-thermometer-three-quarters fas-left"></i><translate>TEMPERATURE</translate></button-->
      <!--button @click="scrollToAnchorPoint('fanConfig')" type="button" class="fI btn btn-secondary btn-main configBtn"><i class="fas fa-fan fas-left"></i><translate>FANS</translate></button-->
      <!--button v-if='power_scaling' @click="scrollToAnchorPoint('powerScaleConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-battery-three-quarters fas-left"></i><translate>POWERSCALE</translate></button-->
      <!--span> | </span-->
      <FormulateInput class="configSubmitBtn" type='submit'><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE CHANGES</translate></FormulateInput>
    </div>

    <div v-else class="mainFooter" style="margin-left:0;margin-right:0;">
      <!--button @click="scrollToAnchorPoint('basicConfig')" type="button" class="btn btn-primary btn-main configBtn pulsate"><i class="fas fa-cog fas-left"></i></button-->
      <!--button v-if="autotuning" @click="scrollToAnchorPoint('autotuneConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-tachometer-alt fas-left"></i></button-->
      <!--button v-if="autotuning && !autotuning.enabled" @click="scrollToAnchorPoint('globalPowerConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-bolt fas-left"></i></button-->
      <!--button v-if="autotuning && !autotuning.enabled" @click="scrollToAnchorPoint('hashChainConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-bars fas-left"></i>PER CHAIN</button-->
      <!--button @click="scrollToAnchorPoint('poolConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-bezier-curve fas-left"></i></button-->
      <!--button @click="scrollToAnchorPoint('temperatureConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-thermometer-three-quarters fas-left"></i></button-->
      <!--button @click="scrollToAnchorPoint('fanConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-fan fas-left"></i></button-->
      <!--button v-if='power_scaling' @click="scrollToAnchorPoint('powerScaleConfig')" type="button" class="btn btn-secondary btn-main configBtn"><i class="fas fa-battery-three-quarters fas-left"></i></button-->
      <!--span> | </span-->
      <FormulateInput class="configSubmitBtn" type='submit'><i class="fas fas-xsBtn fa-save fas-left"></i></FormulateInput>
    </div>

  </span>

</template>

<script>
export default {
  name: 'config-footer',
  props: ['power_scaling', 'autotuning', 'parentRefs', 'isMobile'],
  methods: {
    scrollToAnchorPoint (refName) {
      const el = this.parentRefs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
